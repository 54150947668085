:root {
  --white: #ffffff;
  --whitef4: #f4f4f4;
  --whitef5: #f5f5f5;
  --whiteffb5: #acafb5;
  --white80: #ffffff80;
  --white6e80: #68696e80;
  --whiteEC: #ececec;
  --blue7A6: #0047a6;
  --blue08b: #00008b;
  --black: #161618;
  --black000: #000000;
  --black80: #16161880;
  --grayC4: #c4c4c4;
  --gray55: #555555;
  --grayCCC: #cccccc;
}

.customerdashboard_maintext {
  margin-top: 35px;
  margin-left: 35px;
  font-weight: 700;
  text-shadow: 0 2px 2px var(--blue7A6);
}

.customerdashboard_mainicon {
  font-size: 38px;
  color: var(--blue7A6);
}

/* custom  slider carousel-prev_btn-section */
.carousel-prev_btn-section,
.carousel-next_btn-section {
  width: 7%;
  padding-top: 24%;
}
.slider_image-section {
  width: 80%;
}

.carousel-prev_btn {
  border-radius: 28px;
  background: var(--black000);
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-prev_btn-icon {
  color: var(--white);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.slider_image-section {
  width: 100%;
  height: 351px;
}

.slider_image {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.chargingSubSection {
  margin-left: 2%;
  margin-right: 2%;
}

body.light .vehicle-name {
  font-size: 18px;
  font-weight: 600;
  color: var(--black000);
}

body.light .vehicle-listlink {
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: var(--blue7A6);
}

body.light .charging_info {
  border: 2px solid var(--blue7A6);
  border-radius: 8px;
}

body.light .charging_info1 {
  width: 35%;
  margin-top: 8px;
  margin-bottom: 8px;
  border-right: 2px solid var(--blue7A6);
}
.charging_info2 {
  width: 35%;
  margin-top: 8px;
  margin-bottom: 8px;
}
.charging_info3 {
  width: 30%;
}

body.light .battry_icon {
  font-size: 34px;
  color: var(--blue7A6);
}
.battery-text {
  color: #8a8a8a;
  font-size: 16px;
  font-weight: 700;
}

body.light .bike_kilometer {
  color: #2c2d30;
  font-size: 16px;
  font-weight: 700;
}

body.light .charging_info-btn {
  width: 100%;
  height: 81px;
  border: 2px solid var(--blue7A6) !important;
  border-radius: 0px 5px 5px 0px;
  background: var(--blue7A6);
  color: var(--white);
  font-weight: 700;
}
body.light .charging_info-btn:hover {
  color: var(--blue7A6);
  background: #c9c4c448;
  text-shadow: 0px 3px 3px #00000053;
  box-shadow: 0px 3px 3px #00000053;
  border-radius: 5px 5px 5px 5px;
}

.explore_section {
  background: var(--whitef5);
  border-radius: 8px;
  padding: 15px;
}

.explore_title {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}

.explore_title-borderbottom {
  text-transform: uppercase;
  border-bottom: 2px solid var(--blue7A6);
}

.explore_charging_like {
  cursor: pointer;
}
.explore_charging_iconsec {
  width: 65px;
  height: 65px;
  background: var(--blue7A6);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.explore_charging_iconsec2 {
  width: 65px;
  height: 65px;
  background: #a96017;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.explore_charging_icon {
  font-size: 24px;
  color: var(--white);
}

.explore-menutext {
  font-size: 16px;
  font-weight: 400;
}

.explore_mapsection {
  height: 380px;
  border-radius: 18px;
  background: #4573ff;
  overflow: hidden;
}

.map_nearesttext-section {
  height: 80px;
  background: #8a8a8a;
  border-radius: 18px 18px 0px 0px;
  padding: 15px;
  cursor: pointer;
}

.map_nearbytext {
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  text-align: center;
}

.map_nearesttext {
  font-size: 10px;
  font-weight: 600;
  color: var(--white);
  text-align: center;
}

body.light .news_headingtext {
  font-size: 22px;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--black000);
}
body.light .news_headingtext span {
  border-bottom: 2px solid var(--blue7A6);
}

body.light .news_viewmore-like {
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  color: var(--blue7A6);
}

.newslist_section {
  cursor: pointer;
}

.images_border {
  height: 230px;
  border-radius: 11px;
  overflow: hidden;
}
.images_border img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.images_border img:hover {
  opacity: 0.6;
}

body.light .newsimages_text {
  font-size: 16px;
  font-weight: 600;
  color: var(--black000);
}

.plug_btn {
  width: 100px;
  border: 2px solid #00000073;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.image_iconsec {
  width: 51px;
  height: 51px;
}

.plugimage_icon {
  width: 100%;
  height: 100%;
  color: #00000073;
}
.plug_btntext {
  font-size: 16px;
  font-weight: 500;
  color: #00000073;
}

.plug_btnactive {
  width: 100px;
  border: 2px solid #0c2964;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.plugimage_iconactive {
  width: 100%;
  height: 100%;
  color: #0c2964;
}
.plug_btntextactive {
  font-size: 16px;
  font-weight: 500;
  color: #0c2964;
}

body.light .add_veicle_btn {
  background: var(--blue7A6);
  color: var(--white);
  width: 100%;
  border: 2px solid var(--blue7A6);
  border-radius: 21px;
  padding: 8px 0px 8px 0px;
  font-size: 16px;
  font-weight: 700;
}

body.light .add_veicle_btn:hover {
  background: #83858c;
  border-color: #83858c;
  text-shadow: 0 1px 3px #00000074;
  box-shadow: 0 1px 3px #00000074;
}

body.light .charging_viewtext {
  font-size: 16px;
  color: #2b7c42;
  font-weight: 500;
}
body.light .charging_booktext {
  font-size: 16px;
  color: #e82127;
  font-weight: 500;
}

body.light .charging_booklink {
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  cursor: pointer;
  background: var(--blue7A6);
  padding: 5px 10px;
  border-radius: 50px;
}

.slider_oopstext {
  color: #68696E;
  font-size: 18px;
  font-weight: 700;
}
.slider-add_vehicle-btn {
  background: var(--primaryA6);
  color: var(--whiteFF);
  font-weight: 700;
  font-size: 16px;
  border: 1px solid var(--primaryA6);
  text-transform: uppercase;
  border-radius: 21px;
  min-width: 100px;
  padding: 8px 18px;
}
/* vehiclelit_infosection */
.vehiclelit_header-back-btn {
  background: var(--whiteEC);
  border: 1px solid var(--whiteEC);
  border-radius: 8px;
  font-size: 18px;
  padding-bottom: 5px;
}

body.light .vehiclelit_header-title {
  font-size: 16px;
  font-weight: 600;
}

body.light .vehiclelit_header-addvehicle_link {
  font-size: 14px;
  font-weight: 700;
  color: var(--blue7A6);
  cursor: pointer;
}

.vehiclelit_infosection {
  background: var(--whitef5);
  border: 2px solid var(--whitef5);
  border-radius: 15px;
  position: relative;
}
.vehiclelit_infosection:hover {
  border: 2px solid var(--blue7A6);
  box-shadow: 0 1px 3px #00000074;
}
body.light .vehiclelit_infosectionactive {
  background: var(--whitef5);
  border-radius: 15px;
  position: relative;
  border: 2px solid var(--blue7A6);
  box-shadow: 0 1px 3px #00000074;
}

.vehiclelit_small_image {
  width: 68px;
  height: 68px;
  border-radius: 13px;
}

.vehiclelit_small_image img {
  padding: 5px;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.vehiclelist_text {
  margin-left: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.vehiclelist_infotext {
  margin-left: 15px;
  font-size: 12px;
  font-weight: 700;
  color: #00000075;
}

.vehiclelist_deletesec {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
}
.vehiclelist_deletebtn {
  border: 2px solid #e8212733;
  background: #e8212733;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.vehiclelist_deleteicon {
  font-size: 24px;
  color: #e82127;
}

.vehiclelist_notfound {
  font-size: 21px;
  font-weight: 700;
  color: #00000075;
  text-align: center;
}

/* vehiclelit_infosection end*/

/* newsdetail */
body.light .news_header_maintitle {
  font-size: 22px;
  font-weight: 800;
  color: var(--black000);
}
body.light .news_header_maintitle span {
  border-bottom: 2px solid var(--blue7A6);
}

.dashboard_newslist {
  cursor: pointer;
}
.news_header_search-section {
  width: 35%;
}

.news_header_search-inputgroup {
  border: 2px solid #e0e0e0;
  box-shadow: 0px 5px 20px 0px #6064701a;
  border-radius: 8px;
}
.news_header_search-inputgroup span {
  background: var(--white);
}
.news_header_search-inputtext {
  color: #a0a0a0 !important;
  font-size: 14px;
  font-weight: 500;
}

.news_header-back-btn {
  background: var(--whiteEC);
  border: 1px solid var(--whiteEC);
  border-radius: 8px;
  font-size: 18px;
  padding-bottom: 5px;
}

.newsdetail_imgessection {
  height: 251px;
}
.newsdetail_imgessection img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 15px;
}
.newsdetail_imgessection img:hover {
  opacity: 0.6;
}

.newsdetail_logo {
  width: 51px;
  height: 51px;
  background: #e82127;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  border-radius: 50%;
}

body.light .newsdetail_logotext {
  padding-top: 18px;
  font-size: 15px;
  font-weight: 500;
  color: var(--black000);
}

body.light .newsdetail_hourtext {
  font-size: 9px;
  font-weight: 400;
  color: var(--black000);
}

body.light .newsdetail_titletext {
  font-size: 16px;
  font-weight: 600;
  color: var(--black000);
}

body.light .newsdetail_description {
  font-size: 14px;
  font-weight: 500;
  color: var(--black000);
}

body.light .newsdetail_viewertotal {
  color: var(--blue7A6);
}
.newsdetail_socialicon {
  cursor: pointer;
}
/* newsdetail end*/

/* dark theme ************************/
body.dark .vehicle-name {
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
}

body.dark .vehicle-listlink {
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: var(--whiteffb5);
}

body.dark .charging_info {
  border: 2px solid var(--whiteffb5);
  border-radius: 8px;
}

body.dark .charging_info1 {
  width: 35%;
  margin-top: 8px;
  margin-bottom: 8px;
  border-right: 2px solid var(--whiteffb5);
}

body.dark .battry_icon {
  font-size: 34px;
  color: var(--whitef4);
}

body.dark .bike_kilometer {
  color: var(--whitef4);
  font-size: 16px;
  font-weight: 700;
}

body.dark .charging_info-btn {
  width: 100%;
  height: 81px;
  border: 2px solid var(--whiteffb5) !important;
  border-radius: 0px 5px 5px 0px;
  background: var(--whiteffb5);
  color: var(--white);
  font-weight: 700;
}
body.dark .charging_info-btn:hover {
  background: var(--gray55);

  border-radius: 5px 5px 5px 5px;
}

body.dark .news_headingtext {
  font-size: 22px;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--white);
}
body.dark .news_headingtext span {
  border-bottom: 2px solid var(--whiteffb5);
}

body.dark .news_viewmore-like {
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  color: var(--grayC4);
}

body.dark .newsimages_text {
  font-size: 16px;
  font-weight: 600;
  color: var(--grayC4);
}

body.dark .vehiclelit_infosectionactive {
  background: var(--grayC4);
  border-radius: 15px;
  position: relative;
  border: 2px solid var(--white80);
  box-shadow: 0 1px 3px #00000074;
}

body.dark .vehiclelit_infosectionactive .vehiclelist_text {
  margin-left: 15px;
  font-size: 16px;
  font-weight: 600;
  color: var(--whitef4);
}

body.dark .vehiclelit_header-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
}

body.dark .vehiclelit_header-addvehicle_link {
  font-size: 14px;
  font-weight: 700;
  color: var(--grayC4);
  cursor: pointer;
}

body.dark .add_veicle_btn {
  background: var(--whiteffb5);
  color: var(--white);
  width: 100%;
  border: 2px solid var(--whiteffb5);
  border-radius: 21px;
  padding: 8px 0px 8px 0px;
  font-size: 16px;
  font-weight: 700;
}

body.dark .add_veicle_btn:hover {
  background: #83858c;
  border-color: #83858c;
  text-shadow: 0 1px 3px #00000074;
  box-shadow: 0 1px 3px #00000074;
}

body.dark .news_header_maintitle {
  font-size: 22px;
  font-weight: 800;
  color: var(--white);
}
body.dark .news_header_maintitle span {
  border-bottom: 2px solid var(--grayCCC);
}

body.dark .newsdetail_logotext {
  padding-top: 18px;
  font-size: 15px;
  font-weight: 500;
  color: var(--white);
}

body.dark .newsdetail_hourtext {
  font-size: 9px;
  font-weight: 400;
  color: var(--whitef4);
}

body.dark .newsdetail_titletext {
  font-size: 16px;
  font-weight: 600;
  color: var(--whitef4);
}

body.dark .newsdetail_description {
  font-size: 14px;
  font-weight: 500;
  color: var(--white80);
}

body.dark .newsdetail_viewertotal {
  color: var(--grayC4);
}

body.dark .charging_viewtext {
  font-size: 16px;
  color: #75d791;
  font-weight: 500;
}
body.dark .charging_booktext {
  font-size: 16px;
  color: #e06b6f;
  font-weight: 500;
}

body.dark .charging_booklink {
  font-size: 16px;
  font-weight: 600;
  color: var(--whitef5);
  cursor: pointer;
  background: var(--whiteffb5);
  padding: 5px 10px;
  border-radius: 50px;
}